<template>
  <div class="testingEquipment">
    <div class="lh step">
      <div class="vm content">
        <div class="vm item" v-for="item in equipment" :key="item.id">
          <template v-if="item.id == 0">
            <img src="~@ass/img/1.4.5/icon_sbjc_ysq_h.svg" class="img" alt="" />
            <div class="bottom">
              <div class="error-icon" v-if="!item.state"></div>
              <div class="text">
                扬声器 {{ active == item.id ? '检测中...' : '' }}
              </div>
            </div>
          </template>
          <template v-else-if="item.id == 1">
            <template v-if="active > 0">
              <img
                src="~@ass/img/1.4.5/icon_sbjc_sxt_h.svg"
                class="img"
                alt=""
              />
            </template>
            <template v-else>
              <img src="~@ass/img/1.4.5/icon_sbjc_sxt.svg" class="img" alt="" />
            </template>
            <div class="bottom">
              <div class="error-icon" v-if="!item.state"></div>
              <div class="text">
                摄像头 {{ active == item.id ? '检测中...' : '' }}
              </div>
            </div>
          </template>
          <template v-else-if="item.id == 2">
            <template v-if="active > 1">
              <img
                src="~@ass/img/1.4.5/icon_sbjc_mkf_h.svg"
                class="img"
                alt=""
              />
            </template>
            <template v-else>
              <img src="~@ass/img/1.4.5/icon_sbjc_mkf.svg" class="img" alt="" />
            </template>
            <div class="bottom">
              <div class="error-icon" v-if="!item.state"></div>
              <div class="text">
                麦克风 {{ active == item.id ? '检测中...' : '' }}
              </div>
            </div>
          </template>
          <template v-else-if="item.id == 3">
            <template v-if="active == 3">
              <img
                src="~@ass/img/1.4.5/icon_sbjc_jcjg_h.svg"
                class="img"
                alt=""
              />
            </template>
            <template v-else>
              <img
                src="~@ass/img/1.4.5/icon_sbjc_jcjg.svg"
                class="img"
                alt=""
              />
            </template>
            <div class="bottom">
              <div class="text">检测结果</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!--扬声器检测-->
    <div class="speaker-test" v-show="active == 0">
      <div class="speaker-item">
        <div class="title">扬声器</div>
        <div class="right">
          <el-select
            v-model="speakerVal"
            @change="switchDevice('speaker', arguments[0])"
          >
            <el-option
              v-for="item in audiooutput"
              :key="item.deviceId"
              :label="item.label"
              :value="item.deviceId"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="speaker-item">
        <div class="title"></div>
        <div class="right">
          <el-button class="speaker-play" @click="playTestAudio">
            点击播放声音测试
          </el-button>
        </div>
      </div>
      <div class="speaker-item">
        <div class="title"></div>
        <div class="right">
          <div class="volume-control">
            <div class="icon"></div>
            <div class="slider">
              <el-slider
                v-model="volumeControlVal"
                @change="setAudioVolume"
              ></el-slider>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-tips">
        <div class="title">可以听到电脑播放声音吗？</div>
        <div class="next-btn">
          <el-button class="btn1" @click="next(true)">可以听到</el-button>
          <el-button class="btn2" @click="next(false)">不可以听到</el-button>
        </div>
      </div>
    </div>
    <!--摄像头检测-->
    <div class="speaker-test" v-show="active == 1">
      <div class="speaker-item">
        <div class="title">摄像头</div>
        <div class="right">
          <el-select
            v-model="cameraVal"
            @change="switchDevice('video', arguments[0])"
          >
            <el-option
              v-for="item in videoinput"
              :key="item.deviceId"
              :label="item.label"
              :value="item.deviceId"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="speaker-item">
        <div class="title"></div>
        <div class="right">
          <div class="video">
            <video ref="video" autoplay muted></video>
            <div class="video-error" v-if="!stream"></div>
          </div>
        </div>
      </div>
      <div class="bottom-tips">
        <div class="title">可以看到自己的视频画面吗？</div>
        <div class="next-btn">
          <el-button class="btn1" @click="next(true)">可以看到</el-button>
          <el-button class="btn2" @click="next(false)">不可以看到</el-button>
        </div>
      </div>
    </div>
    <!--麦克风检测-->
    <div class="speaker-test" v-show="active == 2">
      <div class="speaker-item">
        <div class="title">麦克风</div>
        <div class="right">
          <el-select
            v-model="micVal"
            @change="switchDevice('audio', arguments[0])"
          >
            <el-option
              v-for="item in audioinput"
              :key="item.deviceId"
              :label="item.label"
              :value="item.deviceId"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="speaker-item">
        <div class="title">声波条</div>
        <div class="right">
          <div class="volume-contain">
            <span
              v-for="item in 30"
              :key="item"
              class="volume-item"
              :class="{ active: item < (volume / 100) * 30 }"
            ></span>
          </div>
        </div>
      </div>
      <div class="bottom-tips">
        <div class="title">对着麦克风讲话，是否可以看到声波条波动？</div>
        <div class="next-btn">
          <el-button class="btn1" @click="next(true)">可以看到</el-button>
          <el-button class="btn2" @click="next(false)">不可以看到</el-button>
        </div>
      </div>
    </div>
    <!--检测结果-->
    <div class="speaker-test" v-show="active == 3">
      <div class="testing-result">
        <div class="row row-title">
          <div class="title">检测项目</div>
          <div class="title">检测结果</div>
        </div>
        <div v-for="item in equipmentResult" :key="item.id" class="row">
          <div class="left">{{ item.name }}</div>
          <div class="right">
            <template v-if="item.state">
              <div class="state success">正常</div>
              <el-button
                type="text"
                class="error-btn"
                style="visibility: hidden"
                v-if="!isDevicesSuccess"
              >
                设备正常
              </el-button>
            </template>
            <template v-else>
              <div class="state error">异常</div>
              <el-popover
                placement="left-end"
                title="请按以下方法排查："
                width="260"
                trigger="hover"
              >
                <div
                  v-html="item.errorText"
                  style="font-size: 13px; color: #666; line-height: 20px"
                ></div>
                <el-button class="error-btn" type="text" slot="reference">
                  问题排查
                </el-button>
              </el-popover>
            </template>
          </div>
        </div>
      </div>
      <div class="bottom-tips">
        <div class="next-btn">
          <el-button class="btn1" @click="close(true)">开始上课</el-button>
          <el-button class="btn2" @click="reset">重新检测</el-button>
        </div>
      </div>
    </div>
    <!--关闭按钮-->
    <!-- <div class="close-icon el-icon-close" @click="close(false)"></div> -->
  </div>
</template>
<script>
import 'webrtc-adapter'

export default {
  name: 'testingDevice',

  data() {
    return {
      active: 0,
      options: {
        audio: true,
        video: true,
      },

      equipment: [
        {
          name: '扬声器',
          id: 0,
          state: true,
          errorText:
            '1.请确认扬声器已正确连接并开启<br/>2.如果是外置扬声器，请尝试拔出重新插入<br/>3.尝试重启您的电脑',
        },
        {
          name: '摄像头',
          id: 1,
          state: true,
          errorText:
            '1.请确认摄像头已正确连接并开启<br/>2.确认摄像头没有被其他软件占用（如QQ、微信等）<br/>3.如果是外置摄像头，请尝试拔出重新插入<br/>4.尝试重启您的电脑',
        },
        {
          name: '麦克风',
          id: 2,
          state: true,
          errorText:
            '1.请确认麦克风已正确连接并开启<br/>2.如果是外置麦克风，请尝试拔出重新插入<br/>3.尝试重启您的电脑',
        },
        { name: '检测结果', id: 3, state: true },
      ],

      // 音量大小
      volume: 0,

      // 音量控制大小
      volumeControlVal: 30,

      // 扬声器
      speakerVal: '',
      // 麦克风
      micVal: '',
      // 摄像头
      cameraVal: '',

      // 扬声器
      audiooutput: [],
      // 麦克风
      audioinput: [],
      // 视频
      videoinput: [],

      stream: null,
    }
  },

  computed: {
    // 检测结果
    equipmentResult() {
      return this.equipment.filter((item) => item.id != 3)
    },

    // 检测结果是否全部正常
    isDevicesSuccess() {
      return this.equipmentResult.every((item) => !!item.state)
    },
  },

  watch: {
    active() {
      this.audioStop()
    },

    speakerVal() {
      this.audioStop()
    },
  },

  created() {
    this.initAudioNode()
  },

  mounted() {
    this.init()
  },

  destroyed() {
    this.destory()
  },

  methods: {
    async init() {
      await this.getUserMedia()
      this.enumerateDevices()
      this.onEvent()
    },

    // 获取授权和流
    async getUserMedia() {
      try {
        // 获取用户的 media 信息
        this.stream = await navigator.mediaDevices.getUserMedia(this.options)
        // console.log(this.stream.getAudioTracks())
        // console.log(this.stream.getVideoTracks())
        // console.log('this.options', this.options)
        // 设置画面
        this.$refs.video.srcObject = this.stream
        // audioContext
        this.audioContext = new AudioContext()
        // console.log(this.audioContext)
        // 音频音量大小
        this.onInputAudioProcess()
      } catch (error) {
        this.onError(error)
      }
    },

    // 获取设备
    async enumerateDevices() {
      // 扬声器
      this.audiooutput = []
      // 麦克风
      this.audioinput = []
      // 视频
      this.videoinput = []

      const devices = await navigator.mediaDevices.enumerateDevices()
      devices.forEach((device) => {
        if (device.deviceId !== 'communications') {
          this[device.kind].push(device)
        }
        // console.log(
        //   device.kind + ': ' + device.label + ' id = ' + device.deviceId
        // )
      })
      this.$nextTick(() => {
        // 扬声器
        this.speakerVal =
          this.audiooutput.length > 0
            ? this.audiooutput[0].deviceId
            : this.speakerVal
        // console.log(this.audiooutput, this.speakerVal)

        if (this.stream) {
          // 获取当前音频轨道
          let audioTracks = this.stream.getAudioTracks()
          audioTracks = audioTracks && audioTracks[0] ? audioTracks[0] : [{}]
          // 获取当前视频轨道
          let videoTracks = this.stream.getVideoTracks()
          videoTracks = videoTracks && videoTracks[0] ? videoTracks[0] : [{}]
          // 麦克风
          const micVal = this.audioinput.find(
            (item) => item.label === audioTracks.label
          )
          this.micVal = micVal ? micVal.deviceId : this.micVal
          // // 摄像头
          const cameraVal = this.videoinput.find(
            (item) => item.label === videoTracks.label
          )
          this.cameraVal = cameraVal ? cameraVal.deviceId : this.cameraVal
        }
      })

      // console.log(this.audiooutput, this.audioinput)
    },

    // 音频音量大小
    onInputAudioProcess() {
      // 将麦克风的声音输入这个对象
      const mediaStreamSource = this.audioContext.createMediaStreamSource(
        this.stream
      )
      // 创建一个音频分析对象，采样的缓冲区大小为4096，输入和输出都是单声道
      const scriptProcessor = this.audioContext.createScriptProcessor(
        4096,
        1,
        1
      )
      // 将该分析对象与麦克风音频进行连接
      mediaStreamSource.connect(scriptProcessor)
      // 将该分析对象与麦克风音频进行连接
      scriptProcessor.connect(this.audioContext.destination)
      // 开始处理音频
      scriptProcessor.onaudioprocess = _.throttle((e) => {
        // 获得缓冲区的输入音频，转换为包含了PCM通道数据的32位浮点数组
        const buffer = e.inputBuffer.getChannelData(0)
        // 获取缓冲区中最大的音量值
        const maxVal = Math.max.apply(Math, buffer)
        // 显示音量值
        this.volume = Math.round(maxVal * 100)
      }, 100)
    },

    onEvent() {
      navigator.mediaDevices.ondevicechange = _.debounce(() => {
        // console.log('设备更新')
        this.destory()
        this.init()
      }, 1000)
      // this.stream.onaddtrack = () => {}
      // this.stream.onremovetrack = () => {}
    },

    // 出现错误
    onError(error) {
      switch (error.name) {
        case 'NotAllowedError':
          this.$alert('摄像头与麦克风无权限访问', '温馨提示', {
            confirmButtonText: '我知道了',
          })
          break
        // case 'NotReadableError':
        //   this.$alert('请检测您的摄像头是否处于占用状态', '温馨提示', {
        //     confirmButtonText: '我知道了',
        //   })
        //   break
        case 'NotFoundError':
          if (this.options.video) {
            this.options = {
              video: false,
              audio: true,
            }
            this.init()
          } else {
            this.$alert('请检查您的摄像头与麦克风设备是否正常', '温馨提示', {
              confirmButtonText: '我知道了',
            })
          }
          break
        // default:
        //   this.$alert('设备出现未知错误，请稍后重试', '温馨提示', {
        //     confirmButtonText: '确定',
        //   })
      }
      console.info(error.code, error.message, error.name)
    },

    // 初始化 预先加载
    initAudioNode() {
      this.audioNode = new Audio()
      this.audioNode.autoplay = true
      this.audioNode.preload = 'auto'
      this.setSrc()
      this.setAudioVolume()
    },

    // 设置音频地址
    setSrc(src = 'https://f.dingdingkaike.com.cn/test/audio_test.mp3') {
      this.audioNode.src = src
    },

    // 设置音频音量
    setAudioVolume() {
      this.audioNode.volume = parseFloat(this.volumeControlVal / 100)
    },

    // 播放测试音频
    playTestAudio() {
      this.audioStop()
      this.setSrc()
      this.audioNode.setSinkId(this.speakerVal)
      this.audioNode.play()
    },

    // 音频停止
    audioStop() {
      this.audioNode.pause()
      this.setSrc('')
    },

    // 切换设备
    switchDevice(name, deviceId) {
      // console.log(name, deviceId)
      switch (name) {
        case 'audio':
          this.destory()
          this.options = {
            video: true,
            audio: {
              deviceId: {
                exact: deviceId,
              },
            },
          }
          this.init()
          break
        case 'video':
          this.destory()
          this.options = {
            video: {
              deviceId: {
                exact: deviceId,
              },
            },
            audio: true,
          }
          this.init()
          break
        case 'speaker':
          break
      }
    },

    // 下一步
    next(state) {
      // console.log(this.active, state)
      this.equipment = this.equipment.map((item) =>
        item.id === this.active ? Object.assign({}, item, { state }) : item
      )
      this.active += 1
    },

    // 重新检测
    reset() {
      this.equipment = this.equipment.map((item) =>
        Object.assign({}, item, { state: true })
      )
      this.active = 0
    },

    // 卸载
    destory() {
      this.options = {
        audio: true,
        video: true,
      }

      if (this.audioContext) {
        this.audioContext.close()
        this.audioContext = null
      }

      if (this.stream) {
        const tracks = this.stream.getTracks()

        tracks.forEach(function (track) {
          track.stop()
        })
      }

      this.$refs.video.srcObject = null
      this.stream = null
    },

    // 关闭界面
    close(state) {
      this.$store.dispatch('sendToWindowsMsg', {
        type: 10,
        state,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.testingEquipment {
  width: 700px;
  height: 460px;
  position: relative;
  margin: 0 auto;
  background: #30323d;
  // border-radius: 8px;
  .step {
    height: 130px;
    font-size: 0;
    text-align: center;
    background: #404352;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    .content {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 82%;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        border-top: 2px #979797 dotted;
      }
      .item {
        z-index: 3;
        position: relative;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
        .img {
          display: block;
          margin: 0 auto;
        }
        .bottom {
          display: flex;
          min-width: 102px;
          margin-top: 10px;
          align-items: center;
          justify-content: center;
          .text {
            color: #fff;
            font-size: 14px;
            text-align: center;
          }
          .error-icon {
            width: 16px;
            height: 16px;
            margin-right: 8px;
            background: url('~@ass/img/1.4.5/icon_sbjc_gth.svg') no-repeat
              center;
          }
        }
      }
    }
  }
  .speaker-test {
    margin-top: 30px;
    .speaker-item {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      justify-content: center;
      .title {
        font-size: 12px;
        color: #ffffff;
        min-width: 36px;
        margin-right: 28px;
      }
      ::v-deep .right {
        width: 476px;
        .el-select {
          border: 0;
          width: 100%;
          .el-input__inner {
            border: 0;
            width: 100%;
            display: block;
            height: 34px;
            font-size: 12px;
            color: #fff;
            background: #47494e;
            border-radius: 2px;
          }
          .el-input__icon {
            line-height: 34px;
          }
        }
        .speaker-play {
          border: 0;
          width: 152px;
          height: 34px;
          color: #fff;
          font-size: 12px;
          background: #47494e;
          border-radius: 4px;
          &:hover {
            background: #fa6400;
          }
        }
        .volume-control {
          display: flex;
          align-items: center;
          .icon {
            width: 16px;
            height: 13px;
            background: url('~@ass/img/1.4.5/icon_ypyl.svg') no-repeat center;
          }
          .slider {
            width: 200px;
            margin-left: 20px;
            transform: scale(0.7);
            transform-origin: left center;
            .el-slider__bar {
              background-color: #fa6400;
            }
            .el-slider__button {
              border-color: #fff;
              background-color: #fff;
            }
          }
        }
        .video {
          width: 220px;
          height: 124px;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
            background: #47494e;
          }
          .video-error {
            width: 60px;
            height: 60px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: url('~@ass/img/1.4.5/icon_sbjc_wsxt.svg') no-repeat
              center;
          }
        }
        .volume-contain {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .volume-item {
            width: 6px;
            height: 20px;
            background: #ececec;
            border-radius: 3px;
            &.active {
              background: #fa6400;
            }
          }
        }
      }
    }
  }
}
.bottom-tips {
  position: absolute;
  bottom: 30px;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  .title {
    font-size: 15px;
    color: #ffffff;
  }
  ::v-deep .next-btn {
    margin-top: 20px;
    .btn1,
    .btn2 {
      border: 0;
      height: 34px;
      width: 100px;
      color: #fff;
      font-size: 12px;
    }
    .btn1 {
      background: #fa6400;
      &:hover {
      }
    }
    .btn2 {
      background: #47494e;
      &:hover {
      }
    }
  }
}
.testing-result {
  width: 400px;
  font-size: 12px;
  color: #ffffff;
  margin: 0 auto;
  border-left: 1px solid #404352;
  border-right: 1px solid #404352;
  .row {
    line-height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #404352;
    &.row-title {
      line-height: 34px;
    }
    .title {
      flex: 1;
      border-bottom: 0;
      background: #404352;
    }
    .left {
      flex: 1;
    }
    ::v-deep .right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .state {
        &.success {
          color: #6acf70;
        }
        &.error {
          color: #ff3535;
        }
      }
      .error-btn {
        margin-left: 20px;
      }
      .el-button {
        font-size: 12px;
        color: #fa6400;
      }
    }
  }
}
.close-icon {
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}
</style>